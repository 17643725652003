<template>

	<div class="con" ref="imageTofile">


		<div>
			<audio id="cheerMusic" ref="music" preload="auto" loop="loop"
				src="https://ynlothar-1256952774.cos.ap-chengdu.myqcloud.com/%E6%94%BE%E6%9D%BE%E9%9F%B3%E4%B9%90%E7%9B%92%20-%20%E8%BD%BB%E9%9F%B3%E4%B9%90%E7%BA%AF%E9%9F%B3%E4%B9%90%20%28%E9%92%A2%E7%90%B4%E6%9B%B2%29.mp3"></audio>

		</div>

       <div class="change" @click="change" v-if="qy==1">切换模型</div>
		<div class="loading" v-if="show">
			<div class="item"></div>
			<div class="item"></div>
			<div class="item"></div>
			<div class="item"></div>
			<div class="item"></div>
			<div class="item"></div>
			<div class="item"></div>
			<div class="item"></div>
			<div class="time">长按{{ timer }}秒</div>

			<!-- <div v-html='audios'> </div> -->

		</div>

		<!-- <img class="music" src="/static/img/music.png" alt=""> -->
		<img ref="jt" v-show="show" class="jiantou" src="/static/img/jiantou2.png" alt="">
		<div id="container"></div>

       
	   <div v-if="display" class="boxBackground">
	     	
	   		<p class="tip">人体正在加载中  <van-loading /></p> 
	   	
	     </div>
		 
		 <div v-if="load" class="boxBackground">	   	
		 		<p class="tip">处理中  <van-loading /></p> 	
		   </div>
		 		 
		 
		 


	</div>

</template>

<script>
	import {
		Toast
	} from 'vant';
	import wx from 'weixin-js-sdk';
	import * as THREE from 'three';

	import html2canvas from "html2canvas";
	import {
		Color
	} from 'three';
	import {
		OrbitControls
	}
	from 'three/examples/jsm/controls/OrbitControls.js';
	import {
		GLTFLoader
	} from 'three/examples/jsm/loaders/GLTFLoader.js';


	// 导入dat.gui
	import * as dat from "dat.gui";








	export default {
		data() {
			return {

				show: false,
				shoes: false,
				audios: '',
				music: null,
				order: '',
               display:true,
			   load:false,
			   type:1,
			   param:{
				   order_number:'',
				   img:''
			   },
			   
			   md_id:'',
			   
			   bodys:['dz','zs'],
			   

				scene: null,
				renderer: null,
				camera:{
					'aspect':null
				},
				mixer: null,
				dirLight: null,
				plane: null,
				model: null,
				meshes: null,
				raycaster: null,
				mouse: null,
				clock: null,

				index: 0,
				timer: 30,
				interval: null,

				currentRate: 30,

				manager: null,
				controls: null,

				htmlUrl: '',
				qy:0,
				token:''
				

			}
		},

		created() {

		
			
			
			var url=window.location.href  //h5页面获取当前页面url路径
			console.log('url',url)
			   var dz_url = url.split('#')[0];                //获取#/之前的字符串
			    var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
			    var cs_arr = cs.split('&');                    //参数字符串分割为数组
			    var cs={};
			    for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
			      cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
			    }
			 
										   
			
			let token = cs.token;
			this.token = token;
			this.type = cs.type;
            this.qy = cs.qy;

			
			console.log('token1', token)
			console.log('qy', this.qy)
			window.sessionStorage.setItem('token', token)

		},
		mounted() {
			let that = this;
    


			this.music = document.getElementById('cheerMusic')



			this.raycaster = new THREE.Raycaster(); //射线投影
			this.mouse = new THREE.Vector2(0, 0); //鼠标位置信息

			this.clock = new THREE.Clock();

			this.manager = new THREE.LoadingManager(); //定义加载跟踪容器

			this.manager.onLoad = function() {
            //    that.display = false
			//	console.log(that.display)
            //  console.log('加载成功');
			
			
				/* 	console.log('加载成功');
					console.log('123', UiJinDuApp.timer)
					UiJinDuApp.display = false
					dracoLoader.dispose(); */

			};
			
			





			window.addEventListener('resize', function() {
				this.camera.aspect = window.innerWidth / window.innerHeight;
				this.camera.updateProjectionMatrix();
				this.renderer.setSize(window.innerWidth, window.innerHeight);
			})

			document.addEventListener('touchend', (event) => {
				if(this.display){
					return false;
				}
				
				this.show = false;

				this.music.pause()

				clearInterval(this.interval)
			})

			//touchstart  mousedown
			document.addEventListener('touchstart', (event) => {

                if(this.display){
					return false;
				}
				//console.log('长按', event);


				//return;

				this.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1; /// -1 ~ 1;
				//屏幕坐标y朝下  三维坐标y朝上 
				this.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1; /// -1 ~ 1;


				this.raycaster.setFromCamera(this.mouse, this.camera); //鼠标射线

				const intersection = this.raycaster.intersectObject(this.meshes); //鼠标和射线的交集
                let name = '';

				//if (intersection.length > 1) {
				if (intersection.length > 0) {
					
					console.log('intersection',intersection)
					
					/* intersection.forEach(item=>{
						
					
						
						//console.log('name',name)
						if(this.bodys.indexOf(name) != -1){
							console.log('name2',name)
						}
									
						
					}) */
					
				
					//	  name = intersection[0].object.name;
						  
						 
						  
						//  if(this.bodys.indexOf(name) != -1){
						  	 
						/*  	 if(name == "dz"){
						  	 	this.md_id = 1;
						  	 }else{
						  	 	this.md_id = 2;
						  	 } */
						  	 
						  	 
						 // 	 console.log('name---------', intersection[0]['object']['name'])
						  //	 console.log('intersection', intersection)
						  	 //	if (intersection[0]['object']['name'] == 'dz') {
						  	 
						  	 
							 this.md_id = 1;
							 
						  	 this.music.currentTime = 3
						  	 
						  	 this.show = true;
						  	 
						  	 this.$nextTick(() => {
						  	 	//    this.music.load(); // 重新加载音频
						  	 
						  	 
						  	 	let jt = this.$refs.jt;
						  	 	console.log('jt',jt)
						  	 	let x = event.changedTouches[0]['clientX'];
						  	 	let y = event.changedTouches[0]['clientY'];
						  	 	jt.style.left = x - jt.width / 2 + 'px';
						  	 	jt.style.top = y - jt.height / 2 + 'px';
						  	 
						  	    
						  	 
						  	 })
						  	 
						  	 
						  	 this.timer = 180;
						  	 
						  	 clearInterval(this.interval)
						  	 this.interval = setInterval(() => {
						  	 	this.timer--;
						  	 
						  	     if(this.timer == 8){
						  	 		this.music.play()
						  	 	}
						  	 
						  	 	if (this.timer <= 0) {
						  	 		this.show = false;
						  	 		this.load = true;
						  	 
						  	 		this.music.pause()
						  	 		this.toImage()
						  	 
						  	 
						  	 		clearInterval(this.interval)
						  	 		return false;
						  	 		window.location.href = "weixin://dl/business/?t=gh_6c7a56652a52";
						  	 		wx.miniProgram.switchTab({
						  	 			url: '/pages/article/article'
						  	 		})
						  	 
						  	 		wx.miniProgram.navigateTo({
						  	 			url: '/pages/article/article'
						  	 		})
						  	 
						  	 	}
						  	 
						  	 }, 1000)
						  	 				
							
			
						//  }
						  
			
					

				
				}
			})





			this.initRenderer();
			this.initScene();
			//this.initAxesHelper();

			this.initCamera();

			this.initLight();
			this.initMeshes();

			//this.enableShadow(); //影子

			this.initControls();
		},
		

		methods: {

           change(){
			    
				
					if(this.type == 1){
						this.type = 0;
					}else{
						this.type = 1;
					}
					console.log('type---',this.type)
					window.location.href='https://3dh5.ynlothar.com?type='+this.type+'&token='+this.token+'&qy='+this.qy;
				
				
		   },

			async getAdsList(data) {

				let res = await this.$api.getAdsList(data); 
				console.log(111);


				//this.loading = false;
				//this.TaskList();
			},

			async expend(data) {

				let res = await this.$api.expend({'md_id':this.md_id});
				if (res.code == 300) {
					this.param.order_number = res.data;
					this.updateOrderImg();
				} else {
					this.load = false;
					this.$toast(res.msg);
					setTimeout(()=>{
						wx.miniProgram.navigateTo({
							url: '/pages/member/member'
						})
					},1000)
					
				}
			},


			async updateOrderImg() {
                 console.log('param',this.param)
				let res = await this.$api.updateOrderImg(this.param);
				
				this.load = false;
				if (res.code == 300) {
					console.log('res',res)
					
					wx.miniProgram.navigateTo({
						url: '/pages/pingjia/pingjia?order='+this.param.order_number
					})

					
				} else {
					this.$toast(res.msg);
				}
			},




			// 页面元素转图片
			toImage() {
				
				// 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
				html2canvas(this.$refs.imageTofile, {
					backgroundColor: null,
					useCORS: true,
					scale: 1,
				}).then((canvas) => {

					let url = canvas.toDataURL("image/png");
					this.param.img = url; // 把生成的base64位图片上传到服务器,生成在线图片地址
					
					this.expend();

				});
			}, // 图片上传服务器



			initRenderer() {
				this.renderer = new THREE.WebGLRenderer({
					antialias: true,
					preserveDrawingBuffer: true,
					//	canvas: 'my_canvas' 
				});
				this.renderer.setPixelRatio(window.devicePixelRatio);
				this.renderer.setSize(window.innerWidth, window.innerHeight);
				this.renderer.outputEncoding = THREE.sRGBEncoding;

				//container.appendChild( renderer.domElement );

				let container = document.getElementById("container");
				container.appendChild(this.renderer.domElement);
			},

			initControls() {
				let controls = new OrbitControls(this.camera, this.renderer.domElement);
				
				//controls.minDistance = 1;
				
				 controls.maxDistance = 4//最大缩放
			   controls.minDistance = 1 //最小缩放


				
				
				controls.target.set(0, 1, 0);
				controls.update();
			},

			initScene() {
				this.scene = new THREE.Scene();
				this.scene.background = new Color(0xa0a0a0); //背景颜色
				this.scene.fog = new THREE.Fog(0xa0a0a0, 10, 50);
			},

			initAxesHelper() {
				const axesHelper = new THREE.AxesHelper(2);
				this.scene.add(axesHelper);
			},


			addMaterial(nam) {
				var format = '.jpg';
				const glassTexPath = "./static/img/";

				var texLoader = new THREE.TextureLoader(this.manager)
				const tex = texLoader.load(glassTexPath + nam + format);
				//	tex.flipY = false
				tex.encoding = THREE.sRGBEncoding;
				return (tex)
			},

			initLight() {
				const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444)
				this.scene.add(hemiLight);

				/* this.dirLight = new THREE.DirectionalLight(0xffffff);
				this.dirLight.position.set(-3, 10, -10)
				this.scene.add(this.dirLight); */

				this.scene.background = this.addMaterial("bj");


			},


			addPic(src, num, sizea, sizeb, loopA, rota) {


				const videoPlane = new THREE.PlaneGeometry(sizea, sizeb);
				const textureLoader = new THREE.TextureLoader();
				const material = new THREE.MeshStandardMaterial({
					map: textureLoader.load(src),
					transparent: true, //使用背景透明的png贴图，注意开启透明计算
				});
				const plane = new THREE.Mesh(videoPlane, material);
				plane.rotation.x = 90 / 180 * Math.PI;


				/* 	function planeRotation() {
						if (plane) {
							plane.rotation.z -= num;
						}
						requestAnimationFrame(planeRotation);
				
					}
					if (rota) {
						planeRotation()
					} */

				return plane
			},

			addBottom(name, width, height, x = 0, y = 0, z = 0) {

				let plane = this.addPic('/static/img/bagua.png', -0.001, width, height, false, true)
				plane.position.y = y;
				plane.position.z = z;
				plane.position.x = x;

				plane['name'] = name;
				/* plane.rotation.x = 180 / 180 * Math.PI;
				plane.rotation.z = 180 / 180 * Math.PI; */
				plane.rotation.y = 180 / 180 * Math.PI;

				this.meshes['children'].push(plane);
				this.scene.add(this.meshes);

				console.log('abc', this.meshes);
				/* 	
					const gui = new dat.GUI();
					gui.add(this.scene.position, "y")
						.min(-1)
						.max(1)
						.step(0.01) //每次移动的距离
						.name("上下移动")
						
					
					
					gui.add(this.scene.position, "x")
						.min(-1)
						.max(1)
						.step(0.01) //每次移动的距离
						.name("左右移动")
						
					console.log('gui',gui)	
					 */

			},



			addRd(name, width, height, x = 0, y = 0, z = 0) {

				let plane = this.addPic('/static/img/rd.png', -0.001, width, height, false, true)
				plane.position.y = y;
				plane.position.z = z;
				plane.position.x = x;

				plane['name'] = name;
				plane.rotation.x = 180 / 180 * Math.PI;
				plane.rotation.z = 180 / 180 * Math.PI;
				plane.rotation.y = 180 / 180 * Math.PI;

				this.meshes['children'].push(plane);
				this.scene.add(this.meshes);

			},

			initMeshes() {
				// plane
				/* this.plane = new THREE.Mesh(
					new THREE.PlaneGeometry(100, 100),
					new THREE.MeshPhongMaterial({
						color: 0x999999
					})

				);
				this.plane.rotation.x = -Math.PI / 2;
				this.scene.add(this.plane);
 */



				// model 
				const loader = new GLTFLoader();
				let publicPath = process.env.BASE_URL;
			    let url = "";
				console.log('type',this.type)
				if(this.type == 1){
					// url =`${publicPath}static/gltf/nan1.glb` 
					url = "https://ynlothar-1256952774.cos.ap-chengdu.myqcloud.com/nan1.glb"
				}else{
					// url =`${publicPath}static/gltf/nv1.glb` 
					url = "https://ynlothar-1256952774.cos.ap-chengdu.myqcloud.com/nv1.glb"
				}
				
				
				loader.load(url, (gltf) => {
				/* loader.load(`https://ynlothar-1256952774.cos.ap-chengdu.myqcloud.com/nv1.glb`, (gltf) => {	 */

					this.scene.add(gltf.scene);
					this.meshes = gltf.scene

                    this.display = false;



					this.addBottom('bagua', 1, 1, 0, 0, 0)
					
					
					if(this.type == 2){ 
						//女
					//	this.addRd('dz', 0.13, 0.13, 0, 1, 0.13)
						
					//	this.addRd('zs', 0.13, 0.13, -0.52, 1.03, 0.1)
					}else{
						
						
					//	this.addRd('dz', 0.13, 0.13, 0, 1, 0.14)
						
					//	this.addRd('zs', 0.13, 0.13, -0.52, 0.98, 0.13)
					}

				  



					this.animate();

				});
				this.animate();

			},

			initCamera() {
				this.camera = new THREE.PerspectiveCamera(
					45, window.innerWidth / window.innerHeight, 1, 1000);
				this.camera.position.set(0, 1, 3);
				//	this.camera.lookAt(0, 0, 0);



			},

			enableShadow() {
				this.renderer.shadowMap.enabled = true;
				this.dirLight.castShadow = true;
				this.plane.receiveShadow = true;

			},

			animate() {

				/*   
				if (this.meshes['children'][item]['name'] == "bagua") {
					this.meshes['children'][item]['rotation']['z'] += speed
				}
				
				
 */

				//   this.meshes['children'][6]['rotation']['z'] += 0.01;

				//let delta = this.clock.getDelta();
				requestAnimationFrame(this.animate);




				this.renderer.render(this.scene, this.camera);
				if (this.controls) {
					//this.controls.update();
				}

				//	mixer.update(delta);

			}

		}
	}
</script>

<style scoped>
	*{
		padding:0;
		margin:0;
	}
	.con {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		
		width:100vw;
		height:100vh;
		overflow: hidden;
		position: absolute;
		top:0;
		left:0;
		
	}

	#container {

	/* 	height: 100vh;
        z-index:1;
		position: absolute; */
	}

	.music {
		width: 30px;
		height: 30px;
		left: 5px;
		top: 5px;
		position: absolute;
	}

	.jiantou {
		width: 60px;
		height: 60px;
		right: 20px;
		top: 150px;
		position: absolute;
	}












	/* 设置位置 */
	.loading {
		position: absolute;
		/* 居中 */
		top: 10%;
		left: 50%;
		transform: translate(-50%, -50%);
		/* 高度 */
		height: 40px;
		/* 弹性布局 */
		display: flex;
		/* 设置子项在y轴方向居中，应该是设置起点在中间，非常有用，不然动画很怪 */
		align-items: center;


	}

	.time {
		color: #00f6ff;
		font-size: 20px;
	}

	/* 小竖条 */
	.item {
		height: 50px;
		width: 5px;
		background: #fff;
		/* 加margin，使竖条之间有空隙 */
		margin: 0px 3px;
		/* 圆角 */
		border-radius: 10px;
		/* 动画：名称、时间、循环 */
		animation: loading 1s infinite;
		background: #00f6ff;

	}

	/* 设置动画 */
	@keyframes loading {
		0% {
			height: 0px;
		}

		50% {
			height: 50px;
		}

		100% {
			height: 0px;
		}
	}

	/* 为每一个竖条设置延时 */
	.item:nth-child(2) {
		animation-delay: 0.1s;
	}

	.item:nth-child(3) {
		animation-delay: 0.2s;
	}

	.item:nth-child(4) {
		animation-delay: 0.3s;
	}

	.item:nth-child(5) {
		animation-delay: 0.4s;
	}

	.item:nth-child(6) {
		animation-delay: 0.5s;
	}

	.item:nth-child(7) {
		animation-delay: 0.6s;
	}

	.item:nth-child(8) {
		animation-delay: 0.7s;
	}
	
	
	
	.boxBackground {
		/* border-width: 10px; */
		/* border:4px solid rgb(255, 0, 0);   */
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: #000000;
		position: absolute;
		z-index:9;
		
	}
	
	.tip{
		color:#fff;
		font-size: 24px;
		
		color: aliceblue;
		font-size: 30px;
		position: absolute;
		top: 50%;
		left: 25%;
		width: 100%;
		margin-left: -0px;
		margin-top: 23px;
	    z-index:99
	}
	
	.load {
	
	
		height: 20px;
		background: #ffffff;
	}
	
	.tex {
		color: aliceblue;
		font-size: 30px;
		position: absolute;
		top: 50%;
		left: 25%;
		width: 100%;
		margin-left: -0px;
		margin-top: 23px;
	}
	
	.change{
		position: absolute;
		right:15px;
		top:155px;
		color:#fff;
		font-size: 18px;
	}
</style>