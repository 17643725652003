import request from '../utils/request.js';
let $domain = " https://3dd.ynlothar.com/api";
let $domain2 = "https://womanatd.ynlothar.com"
let $scanning = "http://127.0.0.1:38088";

export default {
	getAdsList: (data) => request.get($domain + '/home/getAdsList', data),
	
	expend: (data) => request.post($domain + '/order/expend', data),
	
	updateOrderImg: (data) => request.post($domain + '/order/updateOrderImg', data),
	
	
	
	//游客上传文件
	uploadFile: (data) => request.post($domain + '/home/uploadFile', data, false),

	
}
