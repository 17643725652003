import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { Button } from 'vant';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import api from "./api/index.js"

Vue.prototype.$api = api;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
